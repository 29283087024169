<template>
  <div>
    <table-wrapper
      :fetch-data="fetchData"
      :rows="rows"
      :list-length="userInvoice.length"
      :pager="pager"
      :change-page-size="changePageSize"
      :is-loading="isLoading"
      :manage-filter="manageFilter"
      :toggle-buttons="toggleButtons"
      no-search
      :custom-search="doCustomSearch"
    >
      <template #body>
        <user-invoice-history-row
          v-for="invoice in userInvoice"
          :key="invoice.requestName"
          :user-invoice="invoice"
          statuses="invoice"
        />
      </template>
      <template #noResults>
        <div>
          <loader-info v-if="isLoading" />
          <div v-else>
            <p class="my-4">
              <em>
                <strong>
                  Nessuna fattura disponibile
                </strong>
              </em>
            </p>
          </div>
        </div>
      </template>
      <template #headers>
        <b-thead>
          <b-tr>
            <b-th class="white-space-nowrap">Fattura</b-th>
            <b-th class="white-space-nowrap">Destinatario</b-th>
            <b-th class="white-space-nowrap">Rating</b-th>
            <b-th class="white-space-nowrap">Scadenza</b-th>
            <b-th class="white-space-nowrap">Importo</b-th>
            <b-th class="table-cell--compact">
              <div class="text-center">
                Situazione finanziaria<span class="d-none"> della fattura</span>
              </div>
              <div class="text-center d-flex flex-nowrap">
                <div
                  class="px-1"
                  v-b-tooltip.hover
                  title="Inviata copia di cortesia"
                >
                  <font-awesome-icon :icon="iconInfo" class="fa-fw" />
                  <span class="sr-only">
                      inviato notifica
                    </span>
                </div>
                <div
                  class="px-1 mx-2"
                  v-b-tooltip.hover
                  title="Sollecitata"
                >
                  <font-awesome-icon :icon="iconRemainder" class="fa-fw" />
                  <span class="sr-only">
                      sollecitata
                    </span>
                </div>
                <div
                  class="px-1"
                  v-b-tooltip.hover
                  title="Factoring/cessione"
                >
                  <font-awesome-icon :icon="iconFactoring" class="fa-fw" />
                  <span class="sr-only">
                      factoring/cessione
                    </span>
                </div>
                <div
                  class="px-1 mx-2"
                  v-b-tooltip.hover
                  title="Anticipo fattura"
                >
                  <font-awesome-icon :icon="iconAdvance" class="fa-fw" />
                  <span class="sr-only">
                      anticipo fattura
                    </span>
                </div>
                <div
                  class="px-1"
                  v-b-tooltip.hover
                  title="Incassata"
                >
                  <font-awesome-icon :icon="iconClose" class="fa-fw" />
                  <span class="sr-only">
                      incassata
                    </span>
                </div>
                <div
                  class="px-1 ms-2"
                  v-b-tooltip.hover
                  title="Nota di credito"
                >
                  <font-awesome-icon :icon="iconAborted" class="fa-fw" />
                  <span class="sr-only">
                      nota di credito
                    </span>
                </div>
              </div>
            </b-th>
            <b-th class="table-cell--compact">&nbsp;</b-th>
          </b-tr>
        </b-thead>
      </template>
      <template #filters>
        <div class="mb-2">
          <p class="mb-0">
            <strong>
              Tipologia di filtro
            </strong>
          </p>
          <b-btn
            class="p-0"
            variant="link"
            @click="setFilterType"
          >
            <span class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="searchTypeSwitch"
              >
              <label class="form-check-label" for="searchTypeSwitch">
                Esclusivo/Concatenato
              </label>
            </span>
          </b-btn>
        </div>
      </template>
    </table-wrapper>

<!--    <div class="mb-2 mx-n3 bg-gray-100 px-3 py-2 table-legend">-->
<!--      <div class="mt-2">-->
<!--        <div>-->
<!--          <p>-->
<!--            <strong>Filtra per</strong>-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="d-flex flex-wrap">-->
<!--          <button-toggle-->
<!--            v-for="(btn, index) in toggleButtons"-->
<!--            :key="'btn_'+index"-->
<!--            :icon="btn.icon"-->
<!--            :label="btn.label"-->
<!--            :action="(value) => manageFilter(value, btn.action)"-->
<!--            has-checkbox-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="my-2">-->
<!--        <p class="mb-0">-->
<!--          <strong>-->
<!--            Tipologia di filtro-->
<!--          </strong>-->
<!--        </p>-->
<!--        <b-btn-->
<!--          class="p-0"-->
<!--          variant="link"-->
<!--          @click="setFilterType"-->
<!--        >-->
<!--          <span class="form-check form-switch">-->
<!--            <input-->
<!--              class="form-check-input"-->
<!--              type="checkbox"-->
<!--              role="switch"-->
<!--              id="searchTypeSwitch"-->
<!--            >-->
<!--            <label class="form-check-label" for="searchTypeSwitch">-->
<!--              Esclusivo/Concatenato-->
<!--            </label>-->
<!--          </span>-->
<!--        </b-btn>-->
<!--      </div>-->
<!--    </div>-->
<!--    <loader-info v-if="isLoading" />-->
<!--    <div v-if="userInvoice.length > 0">-->
<!--      <div v-if="!isLoading">-->
<!--        <b-table-simple striped responsive>-->
<!--          <b-thead>-->
<!--            <b-tr>-->
<!--              <b-th class="white-space-nowrap">Fattura</b-th>-->
<!--              <b-th class="white-space-nowrap">Destinatario</b-th>-->
<!--              <b-th class="white-space-nowrap">Rating</b-th>-->
<!--              <b-th class="white-space-nowrap">Scadenza</b-th>-->
<!--              <b-th class="white-space-nowrap">Importo</b-th>-->
<!--              <b-th class="table-cell&#45;&#45;compact">-->
<!--                <div class="text-center">-->
<!--                  Situazione finanziaria<span class="d-none"> della fattura</span>-->
<!--                </div>-->
<!--                <div class="text-center d-flex flex-nowrap">-->
<!--                  <div-->
<!--                    class="px-1"-->
<!--                    v-b-tooltip.hover-->
<!--                    title="Inviata copia di cortesia"-->
<!--                  >-->
<!--                    <font-awesome-icon :icon="iconInfo" class="fa-fw" />-->
<!--                    <span class="sr-only">-->
<!--                      inviato notifica-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="px-1 mx-2"-->
<!--                    v-b-tooltip.hover-->
<!--                    title="Sollecitata"-->
<!--                  >-->
<!--                    <font-awesome-icon :icon="iconRemainder" class="fa-fw" />-->
<!--                    <span class="sr-only">-->
<!--                      sollecitata-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="px-1"-->
<!--                    v-b-tooltip.hover-->
<!--                    title="Factoring/cessione"-->
<!--                  >-->
<!--                    <font-awesome-icon :icon="iconFactoring" class="fa-fw" />-->
<!--                    <span class="sr-only">-->
<!--                      factoring/cessione-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="px-1 mx-2"-->
<!--                    v-b-tooltip.hover-->
<!--                    title="Anticipo fattura"-->
<!--                  >-->
<!--                    <font-awesome-icon :icon="iconAdvance" class="fa-fw" />-->
<!--                    <span class="sr-only">-->
<!--                      anticipo fattura-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="px-1"-->
<!--                    v-b-tooltip.hover-->
<!--                    title="Incassata"-->
<!--                  >-->
<!--                    <font-awesome-icon :icon="iconClose" class="fa-fw" />-->
<!--                    <span class="sr-only">-->
<!--                      incassata-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="px-1 ms-2"-->
<!--                    v-b-tooltip.hover-->
<!--                    title="Nota di credito"-->
<!--                  >-->
<!--                    <font-awesome-icon :icon="iconAborted" class="fa-fw" />-->
<!--                    <span class="sr-only">-->
<!--                      nota di credito-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-th>-->
<!--              <b-th class="table-cell&#45;&#45;compact">&nbsp;</b-th>-->
<!--            </b-tr>-->
<!--          </b-thead>-->
<!--          <b-tbody>-->
<!--            <user-invoice-history-row-->
<!--              v-for="invoice in userInvoice"-->
<!--              :key="invoice.requestName"-->
<!--              :user-invoice="invoice"-->
<!--              statuses="invoice"-->
<!--            />-->
<!--          </b-tbody>-->
<!--        </b-table-simple>-->
<!--        <div class="d-flex">-->
<!--          <b-pagination-->
<!--            v-model="currentPage"-->
<!--            :total-rows="rows"-->
<!--            :per-page="searchBase.size"-->
<!--            class="ms-auto"-->
<!--          >-->
<!--            <template #first-text><font-awesome-icon icon="fast-backward"></font-awesome-icon></template>-->
<!--            <template #prev-text><font-awesome-icon icon="step-backward"></font-awesome-icon></template>-->
<!--            <template #next-text><font-awesome-icon icon="step-forward"></font-awesome-icon></template>-->
<!--            <template #last-text><font-awesome-icon icon="fast-forward"></font-awesome-icon></template>-->
<!--          </b-pagination>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="userInvoice.length === 0">-->
<!--      <p class="mt-4 mb-2">-->
<!--        <em>-->
<!--          <strong>-->
<!--            Nessuna fattura disponibile-->
<!--          </strong>-->
<!--        </em>-->
<!--      </p>-->
<!--    </div>-->
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty, isNotExist } from '@/utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const UserInvoiceHistoryRow = () => import('@/components/userInvoice/UserInvoiceHistoryRow.vue');
// const ButtonToggle = () => import('@/components/helpers/ButtonToggle.vue');
const TableWrapper = () => import('@/components/tableWrapper/TableWrapper.vue');

export default {
  name: 'UserInvoiceHistoryTable',
  // components: {
  //   ButtonToggle, UserInvoiceHistoryRow, LoaderInfo, TableWrapper,
  // },
  components: {
    UserInvoiceHistoryRow, LoaderInfo, TableWrapper,
  },
  props: {
    completeData: Boolean,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: 10,
        sort: [{ field: 'lastModified', direction: 'DESC' }],
        filters: [],
      },
      entityType: null,
      entityId: null,
      submitted: false,
      note: null,
      filters: {
        statusInfoSent: false,
        statusRemainderSent: false,
        statusFactoring: false,
        statusAdvanceInvoice: false,
        statusClose: false,
        statusAborted: false,
      },
      filterType: false,
      customFilters: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['invoice/isLoading'];
    },
    currentPage: {
      get() {
        const paging = this.$store.getters['invoice/invoiceListPageable'];
        return (paging.number + 1) || 1;
      },
      set(value) {
        // console.log('updateSelf', this.searchBase, value);
        if (this.searchBase.page !== value - 1) {
          this.fetchData(false, value);
        }
      },
    },
    pager() {
      return this.$store.getters['invoice/invoiceListPageable'];
    },
    rows() {
      return this.pager.totalElements;
    },
    userInvoice() {
      return this.$store.getters['invoice/invoiceList'];
    },
    iconInfo() {
      return 'paper-plane';
    },
    iconRemainder() {
      return 'hourglass';
    },
    iconFactoring() {
      return 'file-signature';
    },
    iconAdvance() {
      return 'building-columns';
    },
    iconClose() {
      return 'vault';
    },
    iconAborted() {
      return 'comments-dollar';
    },
    toggleButtons() {
      return [
        {
          icon: this.iconInfo,
          label: 'Inviata copia di cortesia',
          action: 'statusInfoSent',
        },
        {
          icon: this.iconRemainder,
          label: 'Sollecitata',
          action: 'statusRemainderSent',
        },
        {
          icon: this.iconFactoring,
          label: 'Factoring/cessione',
          action: 'statusFactoring',
        },
        {
          icon: this.iconAdvance,
          label: 'Anticipo fattura',
          action: 'statusAdvanceInvoice',
        },
        {
          icon: this.iconClose,
          label: 'Incassata',
          action: 'statusClose',
        },
        {
          icon: this.iconAborted,
          label: 'Nota di credito',
          action: 'statusAborted',
        },
      ];
    },
  },
  watch: {
    completeData(val, oldVal) {
      // console.log('watch completeData', val, oldVal);
      if (val !== oldVal && val) this.fetchData(true);
    },
  },
  mounted() {
    console.log('list created');
    this.fetchData(true);
  },
  methods: {
    fetchData(reload, nextPage) {
      console.log('list fectad', this.searchBase, nextPage);
      const currentProfile = this.$store.getters['subject/currentCompany'];
      if (isNotEmpty(currentProfile)) {
        this.entityId = currentProfile.companyId;
        this.entityType = 'company';
      } else {
        this.entityType = 'person';
        this.entityId = this.$store.getters['subject/currentPerson']?.personId;
      }

      if (isNotExist(this.entityId)) return;

      const filters = [];
      if (this.filters.statusInfoSent) {
        filters.push({
          field: 'additionalInfos.sendInfoMail',
          operation: 'EXISTS',
          value: true,
        });
      }
      if (this.filters.statusRemainderSent) {
        filters.push({
          field: 'additionalInfos.sendRemainderMail',
          operation: 'EXISTS',
          value: true,
        });
      }
      if (this.filters.statusFactoring) {
        filters.push({
          field: 'additionalInfos.invoiceSendFactor',
          operation: 'EXISTS',
          value: true,
        });
      }
      if (this.filters.statusAdvanceInvoice) {
        filters.push({
          field: 'additionalInfos.invoicePurchased',
          operation: 'EXISTS',
          value: true,
        });
      }
      const statusFilter = [];
      if (this.filters.statusClose || this.filters.statusAborted) {
        if (this.filters.statusClose) {
          statusFilter.push('CLOSE');
        }
        if (this.filters.statusAborted) {
          statusFilter.push('ABORTED');
        }
        if (statusFilter.length > 0) {
          filters.push({
            field: 'status',
            operation: 'IN',
            value: statusFilter,
          });
        }
      }

      const search = { ...this.searchBase, filters, companyId: this.entityId };

      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['invoice/invoiceListPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      console.log('this.entityId', this.entityId);
      this.$store.dispatch('invoice/invoicesList', {
        search,
        reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    manageFilter(status, value) {
      console.log('manageFilter', status, value);
      this.filters[value] = status;
      this.fetchData(true);
    },
    setFilterType() {
      console.log('changing filterType', this.filterType);
      this.filterType = !this.filterType;
      this.fetchData(true);
    },
    changePageSize(size) {
      // console.log('change size', size);
      this.searchBase.size = size;
      this.customFilters = true;
      this.fetchData(true);
    },
    doCustomSearch() {
      this.customFilters = true;
      this.fetchData(true);
    },
  },
};
</script>

<style scoped>

</style>
